.elevo-nav-item,
.elevo-nav-item * {
  transition-property: background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

.elevo-nav-item:hover,
.elevo-nav-item:active,
.elevo-nav-item.active {
  box-shadow: inset 0px -1px 0px rgba(3, 19, 53, 0.03),
    inset 0px 1px 0px rgba(255, 255, 255, 0.24);
}

.elevo-nav-item.active {
  @apply !bg-nav-item-active-bg;
}
.elevo-nav-item.active:focus {
  @apply bg-nav-active-focus-bg;
}

/* Nav Item Icon */
.elevo-nav-item.active .nav-item-icon {
  @apply text-nav-item-active-icon;
}
.elevo-nav-item.active:hover .nav-item-icon {
  @apply text-nav-active-hover-icon;
}
.elevo-nav-item.active .nav-item-title {
  @apply text-nav-item-active-text;
}

/* Nav Item Badge */
.elevo-nav-item.active .nav-item-badge {
  @apply !bg-nav-item-active-badge-bg;
}
.elevo-nav-item.active:hover .nav-item-badge {
  @apply bg-nav-item-active-badge-bg;
}
.elevo-nav-item.active:focus .nav-item-badge {
  @apply bg-nav-active-focus-badge-bg;
}
