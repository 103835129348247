@tailwind base;
@tailwind components;
@tailwind utilities;

.home-main-grid {
  @apply grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8;
}

.home-main-wrapper {
  @apply block space-y-4 md:space-y-0 md:flex md:space-x-4 lg:space-x-8;
}
