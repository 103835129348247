:root {
  --accordion-animation-transition-duration: 200ms;
}

.elevo-accordion [data-state='open'] .elevo-accordion-caret {
  transition: transform var(--accordion-animation-transition-duration);
  transform: rotate(90deg);
}

.elevo-accordion [data-state='closed'] .elevo-accordion-caret {
  transition: transform var(--accordion-animation-transition-duration);
  transform: rotate(0deg);
}

.elevo-accordion .accordion-content {
  overflow: hidden;
}

.elevo-accordion .accordion-content[data-state='open'] {
  animation: accordion-content-open 200ms ease-out forwards;
}
.elevo-accordion .accordion-content[data-state='closed'] {
  animation: accordion-content-close 200ms ease-out forwards;
}

.old-browser-accordions summary {
  list-style: none;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

details[open].old-browser-accordions summary > span {
  transform: rotate(90deg);
}

@keyframes accordion-content-open {
  from {
    height: 0;
  }

  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes accordion-content-close {
  from {
    height: var(--radix-accordion-content-height);
  }

  to {
    height: 0;
  }
}
