@import "v1/index.css";
@import "v2/index.css";
@font-face {
  font-family: ProximaNova;
  font-weight: normal;
  src: url("./fonts/ProximaNova-Regular.otf") format("opentype");
}
@font-face {
  font-family: ProximaNova;
  font-weight: bold;
  src: url("./fonts/ProximaNova-Bold.otf") format("opentype");
}
@font-face {
  font-family: ProximaNova;
  font-weight: 600;
  src: url("./fonts/ProximaNova-Semibold.otf") format("opentype");
}
@font-face {
  font-family: ProximaNova;
  font-weight: 300;
  src: url("./fonts/ProximaNova-Light.otf") format("opentype");
}
@font-face {
  font-family: "Material Symbols Rounded";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Material-Symbols-Rounded.woff2") format("woff2");
}
.material-icons {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 20;
  font-family: "Material Symbols Rounded";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  vertical-align: top;
  text-align: center;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}